var ALMAuiMainNavigation = function (params) {


    //global variables set from outside:
    var _params = {
        component: jQuery('.ui-navigation'),
        mobile: 960,
        resizeTimeout: 100,
        breadcrump: jQuery('.alma-mobile-breadcrump')
    };
    _params.component.trigger = _params.component.find('.navbar-toggle');
    _params.breadcrump.trigger = _params.breadcrump.find('a');
    _params.breadcrump.pagePlaceholder = _params.breadcrump.find('.page');

    //global variables set from inside:
    var _global = {
        offCanvasNavigation: _params.component.find('.alma-navbar-collapse'),
        levelsDefined: false,
        levels: _params.component.find('.alma-navbar-collapse>ul'),
        levelsTriggers: _params.component.find('.alma-trigger'),
        stage: _params.component.find('.navbar-nav'),
        activeLevel: 0,
        window: jQuery(window),
        body : jQuery('body'),
        event: 'resize',
        history: []
    }

    jQuery.extend(_params, params);

    var _this = {};

    _this.init = function () {
        console.log("UiMainNavigation :: init");

        //init levels

        if (_checkIfMobile() && !_global.levelsDefined) {
            _this.defineLevels();
            _global.offCanvasNavigation.addClass('hidden');
        }

        //event listeners
        _global.window.on(_global.event, function (e) {

            clearTimeout(jQuery.data(this, 'resizeTimer'));

            jQuery.data(this, 'resizeTimer', setTimeout(function () {

                //resize events go here
                if (_checkIfMobile() && !_global.levelsDefined) {
                    _this.defineLevels();
                }


            }, _params.resizeTimeout));
        });

        _params.component.trigger.on('click', function (e) {
            _global.body.toggleClass('ui-mobile-navigation');
            _global.offCanvasNavigation.toggleClass('hidden');
        });

        _global.levelsTriggers.on('click', function (e) {

            e.stopPropagation();

            _global.stage.css('left', '-' + jQuery(this).parent().attr('data-level') * 100 + '%');

            //remember the level we were on
            _global.activeLevel = jQuery(this).parent().attr('data-level');


            //show breadcrump if the level is past 1


            if (_global.activeLevel == 1) _global.history = []; //we clean and start from the beggining.

            if (_global.activeLevel >= 1) {
                _params.breadcrump.addClass('show');
                _params.breadcrump.pagePlaceholder.html(jQuery(this).find('>a').html());

                _global.history.push(jQuery(this).find('>a').html())
            }

        });

        _params.breadcrump.trigger.on('click', function (e) {

            _global.activeLevel = _global.activeLevel - 1;
            if (_global.activeLevel <= 0) {
                _params.breadcrump.removeClass('show');
            }

            _params.breadcrump.pagePlaceholder.html(_global.history[_global.activeLevel - 1]);
            _global.stage.css('left', '-' + _global.activeLevel * 100 + '%');
        });



    };


    //private methods
    var _checkIfMobile = function () {
        return (_global.window.innerWidth() <= _params.mobile);
    }


    //public methods
    _this.defineLevels = function () {
        _global.levelsDefined = true; // makes sure levels are defined just once!

        //go to every node in the navigation three and add a data item, showing it's level and add a left % to pull it to
        //a certain position
        var uls = _params.component.find('.alma-navbar-collapse>ul'),
            i = 100;

        _global.levels.attr('data-level', 1);
        _global.levels.find('>li>ul').attr('data-level', 2);
        _global.levels.find('>li>ul>li>ul').attr('data-level', 3);
        _global.levels.find('>li>ul>li>ul>li>ul').attr('data-level', 4);
    }

    _this.cleanLevels = function () {
        _global.levelsDefined = false; //we clean the levels, so set the variable to false
        _this.removeStyle(_global.levels, 'left');
        _this.removeStyle(_global.levels.find('ul'), 'left');

    }

    _this.removeStyle = function (el, style) {
        var search = new RegExp(style + '[^;]+;?', 'g');

        el.each(function (k, v) {
            jQuery(v).attr('style', function (i, style) {
                return style.replace(search, '');
            });
        });
    }

    _this.init();

    return _this;



}



